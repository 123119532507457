*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $black;
	line-height: 1;
	background: $white;
	position: relative;
}
a {
	text-decoration: none;
	transition: all .4s ease;
	color: $black;
}
p {
	line-height: 1.4;
	margin-bottom: 18px;
}
strong {
	font-weight: 600;
}
input, textarea, select {
	width: 100%;	
	background: $white;
	padding: 14px 6px;
	margin-bottom: 22px;
	border: 2px $black solid;
	border-radius: 6px;
	color: $black;
	font-family: $font-base;
	font-size: 14px;
	transition: all .2s ease-in-out;
	
	&:focus {
		border-color: $gold;
	}

	&.error {
		border-color: red;
	}
}
label {
	margin-bottom: 8px;
	display: block;
}
.button {
	overflow: hidden;
	padding: 0;
	width: 100%;
	border: 2px solid;
	border-color: $gold;
	border-radius: 8px;
	font-size: 16px;	
	font-weight: 600;
	background: $gold;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;

	&:before, & > span {
		padding: .6em 1em;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	}

	&:before {
		color: $gold;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		transform: translate3d(-100%, 0, 0);	    
	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

input.button {
	padding: 12px 18px;
	background: $gold;
	color: $black;
	border: 2px $gold solid;

	&:hover {
		background: $black;
		color: $white;
	}
}

select {
	color: $black;

	option {
		color: $black;
	}
}

a.button { 
	display: block;
	text-align: center;
}