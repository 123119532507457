.contacto {
	background: $black;
	padding: 30px;
	color: $white;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	.menu-info {
		margin-bottom: 32px;

		a {
			color: $white;
			display: block;
			line-height: 1.4;
			margin-bottom: 12px;
			padding: 6px 0 6px 32px;
			background-size: auto 20px;
			background-position: center left;
			background-repeat: no-repeat;

			&.map {
				background-image: url('../img/bg-map.svg');
			}
			&.tel {
				background-image: url('../img/bg-tel.svg');
			}
			&.mail {
				background-image: url('../img/bg-mail-banner.svg');
			}

			&:hover {
				color: $gold;
			}
		}
	}

	.menu-social {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		a {
			margin-right: 22px;

			&:hover {

				.icon {
					fill: $gold;
				}
			}
		}

		.icon {
			width: 32px;
			fill: $white;
			transition: all .3s ease;
		}
	}

	.iram {
		width: 120px;
	}

	@include breakpoint(phablet) {
		padding: 60px;
	}

	@include breakpoint(tablet) {

		h2 {
			font-size: 32px;
		}
		
		.iram {
			width: 80px;
		}

		.menu-social {

			.icon {
				width: 24px;
			}
		}
	}
}

.cotizar {
	background: $bg-grey;
	color: $black;
	padding: 30px;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	#contactForm {

		.button {
			width: 160px;
			margin: 16px auto 0; 
		}	
	}

	@include breakpoint(phablet) {
		padding: 60px;

		#contactForm {
		    display: grid;
			grid-template-columns: repeat(2, 1fr); 
			grid-gap: 20px;			

			input, select {
				height: 44px;
				margin: 0;
			}

			p:nth-of-type(5) {        	
				grid-column: 1 / 3;
			}

			.button {
				grid-column: 1 / 3;
				justify-self: center;
				cursor: pointer;
			}
		}		
	}

	@include breakpoint(tablet) {

		h2 {
			font-size: 32px;
		}

		#contactForm {
			
			input, select {
				height: 50px;
			}

			.button {
				justify-self: end;
				margin: 0;
			}
		}
	}	
}

.asesoramiento {
	padding: 30px;

	form {

		input[name="userfile"] {
			border: none;
		}

		.button {
			width: 160px;
			margin: 16px auto 0; 
		
			&[disabled] {
				background: #999;
				border-color: #999;
				color: #fff;
				cursor: not-allowed;
			}		
		}	
	}

	.check {
		margin: 0;
		
		li {
			font-size: 14px;
			display: flex;
			align-content: center;
			justify-content: flex-start;
			
			label {
				cursor: pointer;
			}

			#agreeBottom {
				height: 20px;
				width: 20px;
				margin: 0 0 0 16px;
			}
			
			a {
				color: $white;
				text-decoration: underline;
			}
		}
	}	

	@include breakpoint(phablet) {
		padding: 60px;

		form {
		    display: grid;
			grid-template-columns: repeat(2, 1fr); 
			grid-gap: 20px;

			input, select {
				height: 44px;
				margin: 0;
			}

			p {
				margin: 0;
				
				&:nth-of-type(5) {        	
					grid-column: 1 / 3;
				}
			}

			.button {
				cursor: pointer;
				margin: 16px 0 0 0;
			}
		}		

		.check {
			justify-self: end;
			align-self: end;
			margin-bottom: 10px;
		}
	}

	@include breakpoint(tablet) {

		form {
			max-width: 1100px;
			margin: 0 auto;
			
			input {
				height: 50px;
			}
		}
		
		#contactForm {
		    display: grid;
			grid-template-columns: repeat(3, 1fr); 

			label {
				margin-bottom: 12px;
			}

			.button {
				align-self: end;
			}
		}

		.check {
			
			label {
				margin: 0;
			}
		}		
	}
}

#contacto {

	@include breakpoint(tablet) {
		display: grid;
		grid-template-columns: 40% 60%;
	}
}