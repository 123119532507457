.company {
	padding: 60px 30px;

	h2 {
		font-size: 24px;
		text-align: center;
		max-width: 1280px;
		margin: 0 auto 22px;
	}

	h3 {
		font-size: 20px;
		margin: 42px auto 22px;
		max-width: 1280px;
	}

	> p {
		max-width: 1280px;
		margin: 0 auto 22px;
	}

	.certificaciones {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 1280px;
		margin: 0 auto;

		img {
			max-width: 220px;
			margin-bottom: 22px;
		}

		+ h2 {
			margin-top: 52px;
		}
	}

	.nosotros {
		max-width: 1280px;
		margin: 0 auto 72px;

		img {
			width: 170px;
			margin: 0 auto 18px;
		}

		h3 {
			margin-bottom: 0;
			text-align: center;
		}

		h5 {
			font-weight: normal;
			margin-bottom: 22px;
			text-align: center;
		}

		p {
			text-align: center;
		}

		li {
			margin-bottom: 32px;

			&:last-child {
				margin: 0;
			}
		}
	}

	@include breakpoint(tablet) {
		background: url('../img/bg-logo-greenarmor.svg') no-repeat;
		background-position: 20px 20px;
		background-size: 160px;
		padding: 60px;

		&.empresa {
			background: url('../img/bg-logo-greenarmor.svg'), url('../img/bg-logo-greenarmor.svg');
			background-repeat: no-repeat;
			background-position: 20px 20px, right 40%; 
			background-size: 160px, 160px;
		}

		h2 {
			font-size: 32px;
			text-align: left;

			&.subtitle {
				margin: 82px auto 22px;
			}
		}

		h3 {

			&.subtitle {
				margin: 82px auto 22px; 
			}
		}

		.certificaciones {
			justify-content: space-around;
			margin-bottom: 82px;

			img {
				margin: 0;
			}
		}
		
		.nosotros {			
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 60px;

			p {
				text-align: left;
			}

			li {
				margin: 0;
			}
		}
	}

	@include breakpoint(laptop) {
		padding: 90px 90px 60px;

		h2 {
			font-size: 42px;
		}

		.certificaciones {

			img {
				width: 280px;
			}
		}
	}
}

.services {
	padding: 0 30px 30px;	

	h2 {
		font-size: 24px;
		text-align: center;
		margin-bottom: 22px;
	}

	&.interna {
		padding-top: 60px;
	}

	.left {
		margin-bottom: 60px;
	}

	.section {
		color: $white;
		background-image: 
			linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(0,0,0,.8)),
			url('../img/seguridad-section.jpg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 420px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 40px;
		margin-bottom: 30px;

		h3 {
			font-size: 24px;
			text-shadow: 1px 1px 4px $black;
			text-align: center;
		}

		.button {
			max-width: 200px;
		}

		&.custodia {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(0,0,0,.8)),
				url('../img/custodia-section.jpg');
		}

		&.valores {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(0,0,0,.8)),
				url('../img/valores-section.jpg');
		}
	}

	&.electronica {

		h2 {
			text-align: left;
			margin: 62px 0 32px;
		}
	}

	&-detalle {

		h3 {
			margin-bottom: 22px;
		}

		article {
			margin-bottom: 32px;
		}

		.figure {
			margin-bottom: 60px;

			&:last-child {
				margin: 0;
			}
		}
	}

	&.transporte-valores {

		.img {
			margin: 62px 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include breakpoint(phablet) {
		padding: 30px 0 0 0;

		.sections {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
		}
		
		&.interna {
			padding: 60px;
		}

		&-detalle {
			display: grid;
		    grid-template-columns: auto 200px;
		    grid-gap: 30px;

		    article {
		    	margin: 0;
		    	align-self: center;
		    }
		}

		&.transporte-valores {

			.img {
				margin: 0;
			}
		}
	}

	@include breakpoint(tablet) {
		padding: 60px 60px 30px;
		background: url('../img/bg-logo-greenarmor.svg') no-repeat;
		background-position: 96% 20px; 
		background-size: 160px;

		&.interna {
			background-position: 20px 20px; 
		}

		h2 {
			font-size: 32px;
			text-align: left;
			max-width: 1280px;
			margin: 0 auto 22px;
		}

		.sections {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
			max-width: 1000px;
			margin: 0 auto;
		}

		.wrapper {
			display: grid;
			grid-template-columns: auto 320px;
			grid-gap: 40px;
			max-width: 1280px;
			margin: 0 auto;

			.left {
				align-self: center;
				margin: 0;
			}

			.img {
				max-width: 320px;				
			}
		}  

		&-detalle {
			display: grid;
		    grid-template-columns: 1fr 50px 1fr;
		    grid-gap: 0;
			max-width: 1280px;
			margin: 0 auto;

		    article {
		    	margin: 0!important;
		    	align-self: center;

				&:nth-child(1) {
				    grid-column: 1 / 2;
				    grid-row: 1 / 2;
				    padding-left: 0;
				}		    	

				&:nth-child(3) {
				    grid-column: 3 / 4;
				    grid-row: 2 / 3;
				    padding-right: 0;
				}

				&:nth-child(5) {
				    grid-column: 1 / 2;
				    grid-row: 3 / 4;
				    padding-left: 0;
				}		    	

				&:nth-child(7) {
				    grid-column: 3 / 4;
				    grid-row: 5 / 6;
				    padding-right: 0;
				}
		    }

		    figure {
		    	margin-bottom: 0!important;
		    	display: flex;
				position: relative;
				align-items: center;

				&:before {
				    content: '';
				    width: 100%;
				    position: absolute;
				    top: 50%;
				    left: 0;
				    background: linear-gradient(to right, #114312, #E9C95A);
				    height: 250px;
				    transform: translateY(-50%);
				    z-index: -1;
				}

				&:last-child {
					margin-left: -60px!important;
				}
		    }

		    .img-left {
			    grid-column: 1 / 2;
			    justify-content: flex-end;
			    margin-left: -60px;
			    
			    &:nth-child(4) {
			    	grid-row: 2 / 3;
			    }
			    
			    &:nth-child(8) {
			    	grid-row: 5 / 6;
			    }				
		    }

			img {
			    height: 400px;
			    width: auto;
			    margin-right: 10%;			    
			}

		    .img-right {
			    grid-column: 3 / 4;
				justify-content: flex-start;
				margin-right: -60px;

			    &:nth-child(2) {
			    	grid-row: 1 / 2;
			    }

			    &:nth-child(6) {
			    	grid-row: 3 / 4;
			    }

			    img {
					margin-left: 10%;
				}
		    }
		}

		&.transporte-valores {
			background: url('../img/bg-logo-greenarmor.svg'), url('../img/bg-logo-greenarmor.svg');
			background-repeat: no-repeat;
			background-position: 20px 20px, 98% 75%; 
			background-size: 160px, 160px;
				
			.wrapper {
				grid-template-columns: repeat(2, 1fr);
			}

			.right {
				grid-column: 2 / 3;
				align-self: center;

				+ .img {
					grid-column: 1 / 2;
					grid-row: 2 / 3;
				}
			}

			.img {
				max-width: none;
			}
		}
	}

	@include breakpoint(laptop) {
    	padding: 0px 90px 60px;

		h2 {
			font-size: 42px;
			margin-bottom: 32px;
		}

		.wrapper {
			grid-gap: 60px; 
		}

		&.electronica {

			h2 {
				padding-right: 20%;
				max-width: 1280px;
				margin: 62px auto 32px; 
			}

			> p {
				max-width: 1280px;
				margin: 0 auto;
			}
		}

		&.transporte-valores {
			background-size: 220px, 220px;
		}
	}

	@include breakpoint(desktop) {
		padding: 0px 0px 60px;
	}
}

.banner-contact {
	padding: 60px 30px 30px 90px;
	background: $black url('../img/bg-mail-banner.svg') no-repeat 30px 80%;
	background-size: 40px;
	color: $white;

	h3 {
		font-size: 24px;
		margin-bottom: 22px;
		text-shadow: 1px 1px 4px $black;
	}

	a {
		color: $white;
		border-bottom: 1px transparent solid;

		&:hover {
			border-bottom-color: $white;
		}
	}

	@include breakpoint(tablet) {
		padding: 60px 60px 60px 30%;
		background-position: 15%;
		background-size: 100px;

		h3 {
			font-size: 32px;
		}

		p {
			font-size: 18px;
			margin-bottom: 12px;
		}
	}

	@include breakpoint(laptop) {

		h3 {
			font-size: 42px;
		}
	}
}

.banner-brochure {
	padding: 30px;
	background: 
		linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.4)),
		url('../img/bg-brochure-m.jpg') no-repeat center;
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	color: $white;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	.button {
		width: 260px;
	}

	&.servicios {
		background: 
			linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.4)),
			url('../img/hero-m.jpg') no-repeat center;
		background-size: cover;
		height: 60vh;
	}

	@include breakpoint(phablet) {
		background-size: cover;
		height: 350px;

		&.servicios {
			height: 45vh;
		}
	}

	@include breakpoint(tablet) {
		background-image: 
			linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.4)),
			url('../img/bg-brochure.jpg');
		background-size: cover;
		height: 70vh;
		padding: 60px;
		justify-content: center; 
		text-align: center;

		h2 {
			font-size: 32px;
		}

		.button {
			margin: 0 auto;
		}

		&.servicios {
			background-image: 
				linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.4)),
				url('../img/hero.jpg');
			background-size: cover;
			height: 75vh;
			text-align: left;

			h2 {
				width: 100%;
				max-width: 1280px;
				margin: 0 auto 22px;
			}

			p {
				font-size: 20px;
				width: 100%;
				max-width: 1280px;
				margin: 0 auto;
			}
		}
	}

	@include breakpoint(laptop) {

		h2 {
			font-size: 42px;
			margin-bottom: 32px;
		}
	}
}

.banner-green {
	padding: 60px 30px 30px;
	background: $green;
	color: $white;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	.figure {
		max-width: 220px;
		margin: 62px auto 0;
	}

	@include breakpoint(tablet) {
		padding: 60px;

		.wrapper {
			display: grid;
			grid-template-columns: auto 280px;
			grid-gap: 60px;
		}

		h2 {
			font-size: 32px;
		}

		p {
			font-size: 18px;
			margin-bottom: 12px;
		}

		.figure {
			margin: 0;
			align-self: center; 
		}
	}

	@include breakpoint(laptop) {

		.wrapper {
			max-width: 1280px;
			margin: 0 auto;
		}

		h2 {
			font-size: 42px;
			justify-self: end;
		}

		.figure {
			justify-self: start;
		}
	}
}

.app-smart {
	padding: 60px 30px;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	.figure {
		max-width: 280px;
		margin: 62px auto 0;
	}

	@include breakpoint(phablet) {

		.wrapper {
			display: grid;
			grid-template-columns: auto 280px;
			grid-gap: 60px;
		}

		.figure {
			margin: 0;
		}

		.column {
			align-self: center;
		}
	}

	@include breakpoint(tablet) {
		background: url('../img/bg-logo-greenarmor.svg') no-repeat;
		background-position: 20px 20px;
		background-size: 160px;
		padding: 60px;

		h2 {
			font-size: 32px;
		}

		p {
			font-size: 18px;
			margin-bottom: 12px;
		}

		.figure {
			margin: 0;
			align-self: center; 
		}
	}

	@include breakpoint(laptop) {

		h2 {
			font-size: 42px;
			justify-self: end;
		}

		.figure {
			justify-self: start;
		}
	}

	@include breakpoint(desktop) {
		padding: 60px 0;

		.wrapper {
			max-width: 1280px;
			margin: 0 auto;
		}
	}
}

.tarifas {
	padding: 0 30px 60px;

	h2 {
		font-size: 24px;
		margin-bottom: 22px;
	}

	.wrapper {
		overflow-x: scroll;
		overflow-y: hidden;
		margin-bottom: 22px;

		+ p {
			font-size: 14px;
			margin-bottom: 42px;
		}
	}

	table { 
		width: 100%; 
		border-collapse: collapse; 
	
		/* Zebra striping */
		tr:nth-of-type(odd) { 
			background: #eee; 
		}
		th { 
			background: #333; 
			color: white; 
			font-weight: bold; 
			vertical-align: middle;
			
			&:first-child {
				text-align: left; 
			}
		}
		td, th { 
			padding: 12px; 
			border: 1px solid #ccc; 
			white-space: nowrap;
			text-align: center;
		}
		td {

			&:first-child {
				text-align: left; 
			}
		}
	}

	@include breakpoint(tablet) {
		padding: 0 60px 60px;

		h2 {
			font-size: 32px;
		}
	}

	@include breakpoint(laptop) {

		h2 {
			font-size: 42px;
			justify-self: end;
			max-width: 1280px;
			margin: 0 auto 32px;			
		}

		p {
			max-width: 1280px;
			margin: 0 auto 12px;			
		}
	}

	@include breakpoint(desktop) {

		.wrapper {
			max-width: 1280px;
			margin: 0 auto 16px;
			overflow: auto;
		}
	}
}

.concurso {
	
	&-grid {
		padding: 30px;

		.picture {
			margin-bottom: 22px;
		}
		h1 {
			font-size: 22px;
		}
		h2 {
			font-size: 30px;
			margin-bottom: 22px;
			text-align: center;
		}
		h3 {
			font-size: 26px;
			margin-bottom: 22px;
			text-align: center;
		}
		li {
			margin-bottom: 52px;
		}
		.items {
			margin-bottom: 46px;
			text-align: center;

			&.win {
				padding: 20px;
				background: #d9bc5e;
				color: $white;

				h1 {
					font-size: 28px;
				}
				
				li {
					margin-bottom: 26px;
				}				
			}
		}

		@include breakpoint(phablet) {

			.items {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 30px;
				
				&.win {
					// padding: 20px;
					// background: #d9bc5e;
					// color: $white;
					
					li {
						margin-bottom: 0px;
					}				
				}				
			}
			li {
				margin: 0;
			}
		}

		@include breakpoint(tablet) {
			padding: 60px;
			max-width: 1600px;
			margin: 0 auto;

			.items {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}