/*#bio_ep_bg {
    background: rgba(0, 0, 0, .75);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
}*/
#bio_ep.popup__wrapper {
    background: #E0FAFE url('../img/bg-popup.svg') no-repeat center left;
    background-size: 400px;
    padding: 40px 60px 40px 410px;
    // max-width: 910px;
    width: 760px;
    height: 500px;
    text-align: left;
    color: #131383;

    h3 {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    p {
        font-size: 12px;
        color: #06061F;
    }
    .badge {
        width: 100px;
        margin-bottom: 14px;
    }
}
#bio_ep_close {
    background: transparent url('../img/close.svg') no-repeat!important;
    width: 28px;
    height: 28px;
    margin: -15px 0 0 -52px;
    transition: transform .2s ease;
    transform: rotate(0turn);
    
    &:hover {
        transform: rotate(.2turn);
    }   
}