.footer {
	padding: 30px;
	background: $bg-grey-d;

	a {
		color: $black;
		font-weight: bold;
		display: block;
		line-height: 1.4;
		border-bottom: 1px transparent solid;
		margin-bottom: 12px;

		&.tel {
			background: url('../img/bg-tel-d.svg') no-repeat center left;
			background-size: 24px;
			padding: 6px 0 6px 32px;
		}

		&.mail {
			background: url('../img/bg-mail-d.svg') no-repeat center left;
			background-size: 28px;
			padding: 6px 0 6px 32px;
		}

		&:hover {
			border-bottom-color: $black;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_burakko {
		color: $bg-grey-d;
		text-align: center;
		display: block;
		padding: 18px 0;
		transition: color .3s ease;
		
		&:hover {
			color: $black;
		}
	}

	@include breakpoint(tablet) {
		padding: 20px 40px;
		display: grid;
		grid-template-columns: repeat(5, 1fr);

		a {
			margin:  0;
			justify-self: center;
			align-self: center;
		}

		&_burakko {
			font-size: 14px;
			font-weight: 800;
		}
	}
}