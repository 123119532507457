.hero {
	background-image: 
		linear-gradient(to top, rgba(0,0,0,0.6) 20%, rgba(0,0,0,.8)),
		url('../img/hero-m.jpg'); 
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 30px 30px;

	h1 {
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		color: $white;
		text-align: center;
		text-shadow: 1px 1px 6px $black;
		animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
		margin-bottom: 22px;

		span {
			color: $gold;
			font-weight: 600;
		}
	}	

	.logo {
		margin-bottom: 32px;

		.icon {
			width: 220px;
			height: 80px; 
		}
	}

	.button {
		max-width: 220px;
		animation: text-focus-in .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both .4s; 		
	}

	&.empresa {
		background-image: 
			linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
			url('../img/hero-empresa-m.jpg'); 
	}

	&.trabaja {
		height: 65vh;
		background-image: 
			linear-gradient(to top, rgba(17,67,18,.3) 20%, rgba(17,67,18,.8)),
			url('../img/hero-trabaja-m.jpg');
		background-position: center bottom;  
	}

	&.empresa.concurso {
		height: 65vh;
		background-image: 
			linear-gradient(to top, rgba(0,0,0,.3) 20%, rgba(0,0,0,.8)),
			url('../img/hero-concurso-m.jpg');
		background-position: center top; 
	}

	&.servicios {
		background-image: 
			linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
			url('../img/hero-servicios-m.jpg'); 
	}

	&.servicios-electronica {
		background-image: 
			linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
			url('../img/hero-servicios-electronica-m.jpg'); 
	}

	&.transporte-valores {
		background-image: 
			linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
			url('../img/hero-transporte-valores-m.jpg'); 
	}

	&.contacto {
		background-image: url('../img/hero-contacto-m.jpg'); 

		h1 {
			color: gold;
			text-shadow: none;
		}

		+ .highlight {
			background: $white;
			color: $gold;
		}
	}	

	@include breakpoint (phablet) {
		background-image:
			linear-gradient(to top, rgba(0,0,0,.6) 20%, rgba(0,0,0,.8)),
			url('../img/hero.jpg');
		background-position: bottom center;
		height: 50vh;

		h1 {
			font-size: 48px;

			span {
				display: block;
			}
		}

		&.empresa {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-empresa.jpg');
		}

		&.trabaja {
			background-image: 
				linear-gradient(to top, rgba(17,67,18,.3) 20%, rgba(17,67,18,.8)),
				url('../img/hero-trabaja.jpg');
		}			

		&.empresa.concurso {
			background-image: 
				linear-gradient(to top, rgba(17,67,18,.3) 20%, rgba(17,67,18,.8)),
				url('../img/hero-concurso.jpg');
		}			

		&.servicios {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-servicios.jpg');
			background-position: top;
		}		

		&.servicios-electronica {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-servicios-electronica.jpg');
			background-position: top;
		}		

		&.transporte-valores {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-transporte-valores.jpg');
			background-position: top;
		}		

		&.contacto {
			background-image: url('../img/hero-contacto.jpg'); 
			background-position: top;

			h1 {
				font-size: 36px;
				text-align: left;
				margin-left: 90px;				
			}			
		}	
	}
	
	@include breakpoint (mobilelandscape) {
		height: auto;
		min-height: 100vh;
	}

	@include breakpoint (tablet) {
		height: calc( 100vh - 48px);
		padding: 40px 0 0 0;

		h1 {
			font-size: 56px;
		}

		.logo {

			.icon {
				width: 280px;
				height: 100px; 
			}
		}
	}
	@include breakpoint (laptop) {
		background-image:
			linear-gradient(to top, rgba(0,0,0,.6) 20%, rgba(0,0,0,.8)),
			url('../img/hero-h.jpg');

		h1 {
			font-size: 62px;
		}

		.logo {

			.icon {
				width: 320px;
				height: 120px;
			}
		}
	}

	@include breakpoint (desktop) {

		h1 {
			font-size: 76px;
		}

		&.empresa {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-empresa-h.jpg');
		}

		&.trabaja {
			background-image: 
				linear-gradient(to top, rgba(17,67,18,.3) 20%, rgba(17,67,18,.8)),
				url('../img/hero-trabaja-h.jpg');
		}		

		&.empresa.concurso {
			background-image: 
				linear-gradient(to top, rgba(17,67,18,.3) 20%, rgba(17,67,18,.8)),
				url('../img/hero-concurso-h.jpg');
		}		

		&.servicios {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-servicios-h.jpg');
		}	

		&.servicios-electronica {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-servicios-electronica-h.jpg');
		}

		&.transporte-valores {
			background-image: 
				linear-gradient(to top, rgba(0,0,0,0) 20%, rgba(0,0,0,.8)),
				url('../img/hero-transporte-valores-h.jpg');
		}	
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-17 11:30:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
						filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
						filter: blur(0px);
		opacity: 1;
	}
}
@keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
						filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
						filter: blur(0px);
		opacity: 1;
	}
}