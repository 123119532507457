.top-header {
	display: none;

	@include breakpoint(tablet) {
		padding: 14px 60px 14px 0;
		display: flex;
		background: $black;
		color: $white;
		justify-content: flex-end;
		align-items: center;

		a {
			color: $white;

			&.tel {
				background: url('../img/bg-tel.svg') no-repeat;
				background-size: 16px;
				padding-left: 26px;
			}
			&.mail {
				background: url('../img/bg-mail.svg') no-repeat;
				background-size: 24px;
				padding-left: 36px;
				margin: 0 68px;
			}

			&:hover {
				color: 	$gold;
			}
		}

		.social {
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				margin-left: 12px;

				a {

					&:hover {

						.icon {
							fill: $gold;
						}
					}
				}
			}
		}

		.icon {
			width: 20px;
			fill: $white;
			transition: all .3s ease;
		}
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: absolute;
	z-index: 10;
	padding: 20px;

	.logo {
		position: relative;
		
		img {
			height: 55px;
		}

		&:after {
			content: url('../img/badge-gptw.svg');			
			width: 44px;
			position: absolute;
			bottom: -100px;
			left: -4px;
		}
	}	

	&__toggle {
		width: 27px;
	}

	&__toggle-line {
		display: block;
		background: $white;
		width: 100%;
		height: 4px;
		margin-bottom: 4px;
		opacity: 1; 
		transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
			transform-origin: center;
		}
		&:nth-child(3) {
			transform-origin: center;
		}
	}  

	&.is-active {
		position: fixed;
		z-index: 11;
		animation: none;

		.header__toggle-line {

			&:nth-child(1) {
				transform: translateY(8px) rotate(45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translateY(-8px) rotate(-45deg);
			}
		}	
	}		

	&.fixed {
	    padding: 12px 18px;
	    position: fixed;
	    animation: headerFix .5s ease-out;
	    background: $black;
	    align-items: center;

		.logo {
			
			&:after {
				content: none;			
			}
		}
	}	

	@include breakpoint(tablet) {
		padding: 20px 40px;

		&.fixed {
			position: absolute;
		}

		&.is-active {
		    position: absolute;
		    background: $black;
		    z-index: 10;
		}		
	}

	@include breakpoint(laptop) {
		padding:  20px 60px;

		.logo {
			
			img {
				height: 50px;
			}
		}

		&__toggle {
		    display: none;
		} 		
	}
}

.menu {
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 11;
	width: 100%;
	height: 100%;
	opacity: 0;
	padding: 120px 30px 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: $black;
	transition: opacity 400ms ease, left 100ms ease 600ms;

	&.is-visible {
		left: 0;
		opacity: 1;
		transition: left 100ms ease, opacity 400ms ease 200ms;
		overflow: scroll;
	}
	
	.menu-inner {
		overflow-x: scroll;
		margin: 0;
		padding-bottom: 30px;
		
		.menu-list {
			list-style: none;
			margin: 0 0 22px 0;
			padding: 0;

			li {
				border-bottom: 1px $gold solid;

				> span {
					font-size: 20px; 
					line-height: 60px;
					color: $white; 
				}

				&:last-child {
					border: none;
				}
		
				> ul {

					li {
						padding-left: 12px;
						border-bottom: none; 
					} 
					
					a {
						font-size: 16px; 
						line-height: 42px;
					}
				}
			}
		}
		
		a {
			font-size: 20px; 
			line-height: 60px;
			color: $white; 
		}		
	}

	.language {
		display: flex;
		justify-content: center;

		img {
			width: 32px;
			height: 32px;
		}

		a {
			margin: 0 16px;
		}
	}

	// .gracias & { display: none;}

	@include breakpoint(mobilelandscape) {
		min-height: 100%;
		height: auto;
		overflow-x: scroll;
	}

	@include breakpoint(tablet) {
		padding: 110px 60px 60px;

		.menu-inner {
			width: 320px;
			padding: 0;
		
			a {
				line-height: 48px;
			}
		}
	}

	@include breakpoint(laptop) {
		position: absolute;
		left: 0;
		top: 78px;
		opacity: 1;
		background: none;
		padding: 0 44px 0 0;
		height: auto;
		align-items: flex-end;
		pointer-events: none;
		
		.menu-inner {
			width: auto;
			display: flex;
			align-items: flex-start;
			margin: 0;
			padding: 0;
			overflow-x: visible;
			
			.menu-list {
				display: flex;
				margin: 0;

				li {
					margin: 0;
					border: none;

					a, span {
						font-size: 18px;   
						line-height: 36px;
						padding: 0 16px;
					}

					&.submenu {
						position: relative;
						pointer-events: auto;
						cursor: pointer;
					}
		
					> ul {
						border: none;
						display:flex;
						flex-direction:column;				
						visibility:hidden;
						position: absolute;
						left:-99999;
						top: 28px;
						transition: none;
						width: 270px;
						padding-top: 8px;

						&:before {
							content: '';
							width: 0;
							height: 0;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 8px solid $black;							
							position: absolute;
							top: 0;
							left: 20px;
						}

						li {
							padding: 0;
						}

						a {
							font-size: 14px; 
							line-height: 1;
							padding: 20px;
							background: $black;
							border-bottom: 1px $black solid;

							&:hover {
								border-bottom-color: $gold;
							}
						}
					}

					&.submenu:hover {

						ul {
							visibility: visible;
							left: 0;
						}
					}
				}
			}	

			a {
				display: block;
				padding: 0 8px;
				pointer-events: auto;
				color: $white;

				&:hover {
					color: $gold;    
				}
			}
		}  		

		.language {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 36px;
			width: 90px;
			margin-left: 20px;
	
			img {
				width: 26px;
				height: auto;
				transition: all .3s ease;
			}
	
			a {
				margin: 0;

				&:hover {
 
					img {
						transform-origin: center;
						transform: scale(1.2);
					}
				}
			}
		}			

		&.fixed {
			top: 0;
			padding: 13px 20px;
			background: $black url('../img/logo-greenarmor.svg') no-repeat;   
			background-size: 30px;
			background-position: 40px center;
			position: fixed;
			animation: headerFix .5s ease-out;
			
			.menu-inner {

				a {

					&:hover {
						color: $gold;
					}	
				}
			}
		}
	}

}

@keyframes headerFix {
	0%   { top: -100px; }
	100% { top: 0; }
}